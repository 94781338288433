import Slider from './slider/slider';

const slider = new Slider();

class Components {

    constructor() {
    }

    onDocumentReady() {
        if ($('.header-slider').length) {
            import('./header_slider/header_slider').then(({
                default: HeaderSlider
            }) => {
                const headerSlider = new HeaderSlider(slider.prevArw(), slider.nextArw());
                headerSlider.init();
            }).catch(error => console.log(`An error occured while loading: ${error}`));
        }

        if ($('.slider_init').length || $('.second_slider_init').length || $('.third_slider_init').length) {
            import('./sliderV1/sliderV1').then(({
                default: SliderVOne
            }) => {
                const sliderVOne = new SliderVOne(slider.prevArw(), slider.nextArw());
                sliderVOne.onInit();

                if ($('.slider_init').length) {
                    sliderVOne.firstSliderInit();
                }

                if ($('.second_slider_init').length) {
                    sliderVOne.secondSliderInit();
                }

                if ($('.third_slider_init').length) {
                    sliderVOne.thirdSliderInit();
                }
            }).catch(error => console.log(`An error occured while loading: ${error}`));
        }

        if ($('.logo_slider_init').length) {
            import('./sliderV2/logoSlider').then(({
                default: LogoSlider
            }) => {
                const logoSlider = new LogoSlider(slider.prevArw(), slider.nextArw());
                logoSlider.init();
            }).catch(error => console.log(`An error occured while loading: ${error}`));
        }

        if ($('.comments_slider_init').length) {
            import('./sliderV4/commentsSlider').then(({
                default: CommentsSlider
            }) => {
                const commentsSlider = new CommentsSlider(slider.prevArw(), slider.nextArw());

                commentsSlider.init();
            }).catch(error => console.log(`An error occured while loading: ${error}`));
        }

        if ($('.home-carousel-wrapper').length) {
            import('./home_carousel/home_carousel').then(({
                default: HomeCarousel
            }) => {
                const homeCarousel = new HomeCarousel(slider.prevArw(), slider.nextArw());
                homeCarousel.init();
            }).catch(error => console.log(`An error occured while loading: ${error}`));
        }

        if ($('.group_carousel_init').length) {
            import('./carousel/carousel').then(({
                default: Carousel
            }) => {
                const carousel = new Carousel(slider.prevArw(), slider.nextArw());
                carousel.init();
            }).catch(error => console.log(`An error occured while loading: ${error}`));
        }

        if ($('#occupation-calendar').length) {
            import('./occupation_calendar/occupation_calendar').then(({
                default: OccupationCalendar
            }) => {
                occupationCalendar();
            }).catch(error => console.log(`An error occured while loading: ${error}`));
        }
    }

    onWindowScroll() {

    }

    onDocumentClick() {
        $(document).on('click', '.social-popup-trigger', function (e) {
            let socialThis = $(this);
            e.preventDefault();
            e.stopPropagation();

            import('./social_share/social_share').then(({
                default: socialShare
            }) => {
                socialShare(socialThis);
            }).catch(error => console.log(`An error occured while loading: ${error}`));
        });

        $(document).on('click', function () {
            $('.social-popup').removeClass('active on');
        });

        $(document).on('click', '#share-icon', function (e) {
            let socialThis = $(this);
            e.preventDefault();
            e.stopPropagation();
            import('./social_share_inner/social_share_inner').then(({
                default: socialShareInner
            }) => {
                socialShareInner(socialThis);
            }).catch(error => console.log(`An error occured while loading: ${error}`));
        });
    }
}

export default Components;