class HomeCarousel {
    constructor(prevArw, nextArw) {
        this.options = {
            slidesToShow: 4,
            slidesToScroll: 1,
            dots: false,
            autoplay: false,
            speed: 800,
            infinite: false,
            prevArrow: prevArw,
            nextArrow: nextArw,
            centerPadding: '0px',
            responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    autoplay: true,
                    autoplaySpeed: 2500
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    autoplay: true,
                    autoplaySpeed: 2500
                }
            }]
        }
    }

    init() {
        $('.home-carousel-wrapper').slick(this.options);
    }

    refresh() {
        $('.home-carousel-wrapper').slick('refresh');
    }
}

export default HomeCarousel;